@import 'variables.scss';

@media only screen and (max-width: 576px) {
    .install-status
    {
        width: 70%;
        margin: 0 auto !important;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .install-status
    {
        width: 60%;
        margin: 0 auto !important;
    }
}

@media (min-width: 992px) {
    .install-status
    {
        width: 40%;
        margin: 0 auto !important;
    }
}


.app-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header-content {
    height: 64px;
    background-color: white;
}

.main-content {
    flex: 1;
    padding: 2vh;
    height: 87vh;
    display: flex;
    flex-direction: column;
}

.dialog-content {
    padding: 0 24px !important;
}

.table-view {
    display: flex;
    flex: auto;
    width: 100%;
}

.pt-15 {
    padding-top: 15px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pr-15{
    padding-right: 15px !important;
}


.pb-10 {
    padding-bottom: 10px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.mb-n5
{
    margin-bottom: -5px !important;
}

.mt-10
{
    margin-top: 10px !important
}

.mt-20
{
    margin-top: 20px !important
}

.mb-10
{
    margin-bottom: 10px !important
}

.root {
    display: flex;
    min-height: 100vh;
    min-width: 100%;
}

header.header-bar {
    width: calc(100% - 245px);
    color: #fff;
}

.add-button-table {
    border-radius: 25px !important;
    float: right;
    margin-bottom: 20px !important;
    margin-right: 20px !important;
    width: 100px;
    height: 50px;
}

.primary-color {
    color: #fff;
    background-color: #3397ff !important;
}

.primary-color-icon {
    color: #3397ff !important;
}

.paven-icon-color {
    color: #fff;
    background-color: #4e6ef3 !important
}

.light-red-color {
    color: #fff;
    background-color: #ff737f !important;
}

.MuiDataGrid-row:nth-child(odd) {
    background-color: #ebf5ff;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
    background-color: #ebebeb !important;
}

.MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: transparent !important;
}

.white-background {
    background-color: white;
}

.create-icon-color {
    color: #6c7075;
}

label.Mui-focused {
    color: green i !important;
}

.MuiOutlinedInput-root .Mui-focused fieldset {
    border-color: white;
}

.input-notched-outline {
    border-width: 1px;
    border-color: #3397ff !important;
}

.add-edit-form {
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 28px;
}

.h-100 {
    height: 100%;
}

/* outline was coming after clicking on row  */
.MuiDataGrid-root .MuiDataGrid-cell:focus {
    outline: none !important;
}

/* outline was coming after clicking on column header  */
.MuiDataGrid-root .MuiDataGrid-colCell:focus{
    outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-row :not(:hover).action-column .MuiIconButton-root {
    visibility: hidden !important;
}

.MuiDataGrid-root .MuiDataGrid-row:hover .action-column .MuiIconButton-root{
    visibility:visible !important;
}

.MuiDataGrid-root .action-header-column{
    display: none !important;
}

 /* hiding all separators that comes after column in table view */
.MuiDataGrid-root .MuiDataGrid-columnSeparator
{
    display: none !important;
}

.card-with-bg.MuiCard-root{
    /* background: $bg-color-input;.MuiCard-root */
    background-color: $bg-color-input;
}

.card-without-shadow.MuiCard-root{
    /* background: $bg-color-input;.MuiCard-root */
    box-shadow: none !important
}


.light-bg-color {
    background: $light-bg-color-input;
}
.float-right
{
    float: right;
}


.theme-color-button{
    background-color: #3397ff !important;
    color : white !important
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.datagrid-header-word-break {
    .MuiDataGrid-colCellTitle {
        word-break: break-word;
        white-space: normal !important;
        line-height: normal;
    }
}

.success{
    color:#3fb569
}

.error{
    color:red
}
